@import '../../../scss/theme-bootstrap';

.product-foundation-vto {
  &__overlay {
    position: relative;
    height: 100%;
  }
  &__overlay-content {
    @include swap_direction(padding, 0 0 0 5%);
    position: absolute;
    width: 100%;
    color: $color-white;
    bottom: 3%;
  }
  &__overlay-title {
    font-family: $bb-sans;
    font-size: 5em;
    margin-bottom: 30px;
    letter-spacing: -1.9px;
    line-height: 70px;
  }
  &__overlay-copy {
    font-size: 1.2em;
    font-family: $body-font;
    letter-spacing: 0.1px;
    width: 55%;
    line-height: 18px;
    margin-bottom: 30px;
    opacity: 0.9;
    max-width: 200px;
  }
  &__overlay-btn {
    @include swap_direction(margin, 5% 5% 0 0);
    color: $color-black;
    background-color: $color-white;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    padding: 0 50px;
    line-height: 37px;
    height: 40px;
    width: 92%;
    font-family: $lato-black-bold;
    text-align: center;
    letter-spacing: 0.2px;
    &.disabled {
      color: $color-gray-lighter;
    }
  }
  &__overlay-image {
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__overlay-close {
    width: 50px;
    height: 50px;
    cursor: pointer;
    position: absolute;
    #{$rdirection}: 10px;
    top: 10px;
    z-index: 9999999;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 38px 38px;
    background-image: url('https://www.bobbibrowncosmetics.ca/media/export/cms/foundation_virtual_try_on/close.png');
  }
}
